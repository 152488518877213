.scanners {
  .content-container.scanners {
    line-height: 140%;
  }

  .content-container {
    height: calc(100vh - 72px - 48px - 96px);
  }

  .scanner-tiles-container {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    row-gap: 10px;
    column-gap: 8px;
    max-width: 1140px;
    margin: auto;
    font-size: 13px;
  }

  .scanner-tile {
    display: flex;
    flex-direction: column;
    width: 374px;
    height: 176px;
    background: white;
    cursor: pointer;
    transition: box-shadow .3s;
    border: 1px solid rgba(46, 60, 84, .1);
    position: relative;
  }

  .scanner-tile:hover {
    box-shadow: 0 8px 20px rgba(46, 60, 84, .2);
  }

  .scanner-tile-main-info {
    display: flex;
    width: 100%;
    height: 97px;
    align-items: center;
    border-bottom: 1px solid rgba(46, 60, 84, .06);
  }

  .scanner-tile-features {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-height: 80px;
    padding: 8px 2px 8px 32px;
  }

  .scanner-tile-features > div {
    display: flex;
    align-items: center;
    margin: 4px;
    width: 112px;
  }

  .scanner-tile-features > div img {
    width: 16px;
    margin-right: 4px;
  }

  .scanner-tile-features > div:nth-child(2), .scanner-tile-features > div:nth-child(5) {
    width: 80px;
    margin-left: 12px;
  }

  .scanner-type-price {
    display: flex;
  }

  .cards-per-hour-num {
    margin-right: 4px;
    font-weight: 500;
  }

  .scanner-tile-main-info > img {
    height: 64px;
    margin-left: 32px;
    margin-right: 16px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 6px;
    font-weight: bold;
  }

  h2 {
    margin-bottom: 10px;
    margin-top: 12px;
    font-size: 24px;
    font-weight: bold;
  }

  p {
    margin-bottom: 0;
  }

  .scanner-price {
    top: 12px;
    right: 12px;
    font-size: 14px;
    position: absolute;
  }

  .cards-per-hour-num {
    padding-left: 3px;
  }

  .supported-scanners-container {
    max-width: 1140px;
    margin: auto;
  }

  .scanner-tiles-container {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .para1 {
    margin-top: 16px;
  }

  .further-notes {
    background: rgba(36,66,117,.08);
    padding: 24px;
    color: rgba(46, 60, 84, .70);
  }

  .main-form-container {
    display: flex;
    width: 100%;
  }

  .main-form-container input {
    width: 400px;
  }

  .main-form-container .row1 {
    margin-right: 24px;
    display: grid;
    grid-template-rows: auto auto auto;
    height: 260px;
  }

  .main-form-container .row2 {
    width: 100%;
  }
  .main-form-container textarea {
    width: 100%;
    height: 221px;
  }

  .new-scanner-help {
    max-width: 1140px;
    margin: 36px auto auto;
  }

  .content-container.scanners form .btn.primary {
    position: relative;
    right: -1028px;
  }

  .para2 {
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .para3 {
    margin-bottom: 28px;
  }

  .float-right {
    float: right;
  }
}
