.batch-details {
  .content-header-main h1 {
    white-space: nowrap;
  }

  .tabs-input {
    margin-right: 12px;
  }

  body {
    overflow: hidden;
  }

  .content-container {
    height: auto;
    padding: 0;
  }

  .batches-container {
    height: calc(100vh - 72px - 96px - 64px - 48px - 48px);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
  }

  .spreadsheet-view-actions {
    display: flex;
    background: white;
    position: sticky;
    top: 0;
    margin: 8px 0;
    .filter-options-container {
      p {
        display: flex; align-items: center;
        margin-right: 8px;
        font-weight: 500;
        font-size: 16px;
      }
      display: flex;
    }
  }

  .content-container.spreadsheet-view-on {
    display: flex;
  }

  .sec-header .info-item {
    margin-right: 24px;
  }

  .sec-header .info-item span{
    font-size: 13px;
    opacity: .7;
    display: block;
    margin-bottom: 4px;
  }

  .sec-header .info-item.status div{
    display: flex;
    align-items: center;
  }
  .sec-header .info-item.status.open p{
    color: #396DC5;
  }
  .sec-header .info-item.status.closed p{
    color: #1F9C77;
  }
  .sec-header .info-item.status.pending p{
    color: #DA9D26;
  }

  .additional-batch-info {
    cursor: pointer;
    color: #EA3553;
  }

  .batch-card {

    background: #FFFFFF;
    border: 1px solid rgba(46, 60, 84, 0.1);
    width: 200px;
    height: 212px;
    max-height: 212px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: box-shadow .3s;
    color: rgba(46, 60, 84, 1);
    margin: 8px;
    font-size: 14px;
    padding-top: 18px;
    position: relative;
  }


  .batch-card:hover {
    box-shadow: 0px 0px 6px rgba(46, 60, 84, .12);
  }

  .batch-card:hover .batch-card-select {
    border: 1px solid #Ea2353;
  }

  .batch-card.not-matched .batch-card-name {
    font-weight: bold;
  }
  .batch-card.not-matched .batch-card-name:hover {
    text-decoration: underline;
  }

  .batch-card.not-matched::after {
    content: "Not matched";
    font-size: 11px;
    font-weight: bold;
    color: #EA3553;
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    width: 94px;
    height: 20px;
    border-radius: 24px;
    background: rgb(252, 236, 239);
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;

  }

  .batch-card-images {
    width: 164px;
    height: 128px;
    display: flex;
    justify-content: space-between;
  }

  .batch-card-images > div {
    width: 79px;
    height: 128px;
    overflow: hidden;
  }

  .batch-card-images img {
    width: 100%;
    height: 100%;
  }

  .batch-card-name {
    margin-top: 11px;
    opacity: .8;
    line-height: 140%;
    width: 164px;
    height: 37px;
    overflow: hidden;
  }

  .batch-card-select {
    position: absolute;
    width: 22px;
    height: 22px;
    background: white;
    border: 1px solid #EEF0F4;
    border-radius: 100%;
    top: -11px;
    right: -11px;
    box-shadow: 0px 0px 4px rgba(46, 60, 84, .16);
    transition: border .3s, background .3s;
  }

  .batch-card.selected {
    box-shadow: 0px 0px 6px rgba(243, 53, 83, .3);
  }

  .batch-card.selected .batch-card-select{
    background-color: #EA3553;
    background-image: url('../images/checkedIcon.svg');
    background-position: center;
    background-size: 16px;
  }


  .btn.secondary.on-dark {
    color: #FF4564;
  }



  .btn.secondary.on-dark:hover {
    color: white;
  }

  .btn img {
    margin-right: 7px;
  }

  .input {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    background: #435066;
    transition: background .3s;
  }

  .input.dropdown {
    cursor: pointer;
  }


  .input img {
    margin-right: 12px;
  }

  .input:hover {
    background: #39465C;
  }

  .content-header-main .column {
    display: flex;
    align-items: center;
  }

  .content-header-main .column.right {
    min-width: 410px;
    justify-content: flex-end;
    width: 100%;
  }

  .content-header-main .column.right .input {
    margin-right: 16px;
  }




  .text-input {
    display: flex;
    flex-direction: column;
  }

  .text-input label {
    display: block;
    margin-bottom: 4px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }





  .more-options-menu {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 48px;
    transition: background .3s;
  }

  .more-options-menu > img {
    display: none;
  }

  .more-options-menu > img {
    opacity: .6;
    transition: opacity .3s;
  }

  .more-options-menu .menu-options {
    position: absolute;
    background: white;
    box-shadow: 0 8px 12px rgba(46, 60, 84, .22);
    right: 0;
    top: 100%;
    flex-direction: column;
    display: none;
  }

  .more-options-menu .menu-options .option {
    padding: 12px 16px;
    border-bottom: 1px solid rgba(46, 60, 84, .12);
  }

  .more-options-menu:hover {
    opacity: 1;
  }

  .more-options-menu:hover > img{
    opacity: 1;
  }

  .has-menu.open {
    box-shadow: none;
    cursor: auto;
  }

  .has-menu:hover .more-options-menu > img{
    display: flex;
  }

  .has-menu .more-options-menu > img{
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .has-menu {
    position: relative;
  }

  .has-menu .more-options-menu {
    z-index: 10;
    cursor: pointer;
  }



  .has-menu.menu-open::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(46,60,84,.2);
    display: flex;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .has-menu.menu-open > .more-options-menu .menu-options{
    display: flex;
  }

  .has-menu.menu-open .more-options-menu{
    background: white;
  }

  .has-menu.menu-open .more-options-menu > img {
    display: flex;
    opacity: 1;
  }



  .input.dropdown {
    position: relative;
  }

  .dropdown-input-options {
    position: absolute;
    background: white;
    color:#172337;
    right: 0;
    top: 100%;
    box-shadow: 0px 12px 23px rgba(46, 60, 84, .4);
    display: none;
    z-index: 1000;
    pointer-events: none;
  }

  .dropdown-input-options select {
    width: 100%;
    height: 100%;
  }

  .dropdown-input-container {
    position: relative;
  }


  .dropdown-input-container.open .dropdownarrow {
    transform: rotate(180deg);
  }

  .sec-footer {
    justify-content: space-between;
    padding: 0 24px;
  }

  .sec-footer > div{
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .sec-footer > div:first-of-type > div:first-of-type{
    margin-right: 16px;
  }

  .sec-footer > div:last-of-type {
    color: #EA3553;
  }

  .sec-footer > div:last-of-type img{
    margin-right: 4px;

  }

  .sec-footer {
    background: white;
    display: flex;
    height: 48px;
    white-space: nowrap;
    width: calc(100vw - 236px);
    box-shadow: -8px 0px 16px rgba(46, 60, 84, 0.32);
  }

  @media screen and (max-width: 1200px) {
    .sec-footer {
      width: calc(100vw);
    }
  }

  .sec-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 64px;
    padding: 0 24px;

    .flag-filters {
      background: #F5F7FA;
      display: flex;
      .flag {
        border: solid 1px rgba(46, 60, 84, 0.1);
        padding: 6px 8px;
        cursor: pointer;
      }
      .flag.selected {
        background: white;
      }
    }
  }

  .sec-header .batch-details-info {
    display: flex;
    align-items: center;
  }

  .breadcrumbs {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 13px;
  }

  .breadcrumbs svg {
    margin: 0 8px;
  }

  .breadcrumbs .current-page {
    opacity: .7;
  }

  .column.left.vert {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .tabs-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #0D1626;
    cursor: pointer;
    padding: 4px;
    border: 1px solid #3A4C6B;
  }

  .tabs-input .tab {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 11px;
    padding-right: 13px;
  }

  .tabs-input .tab img {
    margin-right: 11px;
  }

  .tabs-input .tab.active {
    background: #435066;
  }

  p {
    margin-bottom: 0;
  }

  .hide-column .main-popup {
    max-width: 480px;
  }

  .fill-column .main-popup {
    max-width: 480px;
  }

  .export-batch-options-container {
    width: 100%;
    justify-content: space-evenly;
  }

  .column-checklist-items {
    display: flex;
    height: 480px;
    overflow: auto;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .column-checklist {
    display: flex;
    width: 200px;
    align-items: center;
  }

  .column-checklist .checkbox input{
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .column-checklist > div {
    margin-right: 8px;
  }

  .menu-m .menu-m-options {
    width: 180px;
  }

  .menu-m {
    padding: 0;
    display: flex;
    height: 100%;
    min-width: 24px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  .menu-m .menu-opener {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-m.open .menu-m-options {
    display: flex;
  }

  .menu-m-options {
    position: absolute;
    background: white;
    color: #2E3C54;
    flex-direction: column;
    width: 154px;
    top: 72px;
    right: 24px;
    box-shadow: 0 4px 16px rgba(46, 60, 84, .16);
    z-index: 1000;
  }


  .menu-m-options .option {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    cursor: pointer;
    padding-left: 12px;
    border-bottom: 1px solid rgba(46, 60, 84, .06);
  }

  .menu-m-options .option:hover {
    background: rgba(46, 60, 84, .08);
  }

  .menu-m-options .option img {
    margin-right: 8px;
    width: 20px;
  }

}


.card-item-preview {
  width: 300px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  border: #babfc7 solid 1px;

  h3 {
    margin: 0.5rem 0;
  }
  .no-preview-image {
    height: 150px;
    border: 1px dashed red ;
    display: flex;
    align-items: center;
  }
  .card-item-image-preview {
    margin-top: 10px;
  }
  .card-item-image-preview > span {
    color: red;
    cursor: pointer;
  }
  .card-item-image-preview .back {
    margin-left: 50px;
  }

  .card-item-image-preview .active {
    text-decoration: underline;
  }

  .market-places {
    height: 216px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
  }

  .market-place {
    background: white;
    color: #2e3c54;
    border: 1px solid rgba(46,60,84,.1);
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    max-width: 280px;
    cursor: pointer;
    padding: 14px;
    transition: box-shadow .3s, border .3s, background .3s;
  }

  .market-place img.market-place-logo {
    width: 40px;
    margin-left: 14px;
  }

  .market-place.card-value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    cursor: auto;
  }

  .market-place.card-value .card-value-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .market-place.card-value .card-value-header p{
    margin-left: 16px;
  }

  .market-place.card-value .values{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
  }
  .market-place.card-value .value {
    display: flex;
    flex-direction: column;
  }

  .market-place.card-value .value p {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 14px;
    opacity: .7;
    margin-bottom: 3px;
  }

  .market-place.card-value .value span {
    font-size: 14px;
  }

  .market-place.card-value:hover {
    box-shadow: none;
    border: 1px solid rgba(46, 60, 84, .08);
  }

  .market-place.card-value .more-info {
    position: relative;
  }

  .market-place.card-value .more-info p{
    display: none;
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
    position: absolute;
    background: white;
    z-index: 2000;
    line-height: 150%;
    padding: 12px;
    width: 280px;
    left: -182px;
    top: 26px;
    box-shadow: 0 0 20px rgba(46, 60, 84, .4);
  }

  .market-place.card-value .more-info p::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -10px;
    left: 164px;
  }

  .market-place.card-value .more-info:hover p{
    display: flex;
  }
}

.card-item-preview > img {
  //width: 100px;
  height: 150px;
  margin-top: 12px;
}

.card-item-preview .card-title {
  width: 90%;
  text-align: center;
  margin-top: 12px;
}

.spreadsheet-container {
  height: calc(100vh - 72px - 96px - 64px - 48px - 48px);
  width: 100%;
  overflow: auto;
  display: flex;
  padding: 0 24px;

}

.spreadsheet-table {
  font-size: 14px;
  white-space: nowrap;
  text-align: left;
  border-spacing: 0;
}

.grid-class {
  height: 100%;
  width: 100%;
}


.row-yellow-back {
  background-color: rgba(255, 222, 0, 0.1) !important;
}
.row-red-back {
  background-color: rgba(240, 82, 82, 0.1) !important;
}

.batch-flag-tooltip {
  position: relative;
}
.batch-flag-tooltip .tooltiptext
{
  background-color: #2E3C54 !important;
  visibility: hidden;
  color: white;
  text-align: center;
  border-radius: 5px;
  position: fixed;
  z-index: 2000;
}

.batch-flag-tooltip:hover .tooltiptext
{
  visibility: visible;
}
