.sidebar {
  display: flex;
  z-index: 1000;
  flex-direction: column;
  width: 232px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #2E3C54;
  color: white;
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 2px;
  font-size: 17px;
}

.sidebar h3 {
  font-weight: 700;
  font-family: "Bebas neue",sans-serif;
  display: block;
  font-size: 1.17em;
  margin-bottom: 0;
}

.sidebar .nav-item {
  border-top: 1px solid hsla(0,0%,100%,.04);
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  cursor: pointer;
  background: #2E3C54;
  transition: background .3s;
  position: relative;
}

.sidebar {
  .logo-container {
      width: 100%;
      height: 244px;
      display: flex;
      justify-content: center;
      align-items: center;
    img {
      width: 200px;
    }
  }
  .separator {
      background: white;
  }

  .navigation h3 {
    margin-left: 20px;
  }

  .nav-item:hover {
    background: #273347;
  }

  .nav-item.active {
    background: #273347;
  }

  .nav-item.active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 4px;
    height: 100%;
    background: #EA3553;
  }



  .nav-item.needs-upgrade::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: url('../images/crown icon.svg');
    right: 20px;
  }
}

